import React from "react"
import { Link } from "gatsby"

import SiteLayout from "../../components/layout/site"

import ChatIcon from "../../../static/svgs/chat.svg"
import StreetSignIcon from "../../../static/svgs/streetsign.svg"

export default () => {
  return (
    <SiteLayout title="Support">
      <section className="section section-lg">
        <div className="container">
          <h1>Support</h1>
          <div className="row">
            <div className="col-md-3">
              <Link to="/support/contact-us" className="nounderline">
                <div className="box box-primary text-center">
                  <img
                    src={ChatIcon}
                    height="60px"
                    style={{ margin: "8px" }}
                    alt="icon"
                  />
                  <h4 className="text-primary">Contact Us</h4>
                </div>
              </Link>
            </div>
            <div className="col-md-3">
              <Link to="/support/faq" className="nounderline">
                <div className="box box-primary text-center">
                  <img
                    src={StreetSignIcon}
                    className="text-primary"
                    height="60px"
                    style={{ margin: "8px" }}
                    alt="icon"
                  />
                  <h4 className="text-primary">FAQs</h4>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </SiteLayout>
  )
}
